/* General layout for all pages */
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures footer sticks to the bottom */
}

#content {
  flex: 1 0 auto;
  padding: 20px;
}

.content-container {
  margin: 50px 0 0 20px; /* Top and left margin */
}

.intro {
  font-family: serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.links {
  margin-top: 150px;
  position: relative;
}

.link-item {
  margin-bottom: 15px;
}

.links a {
  display: block;
  font-size: 48px;
  color: blue;
  text-decoration: none;
  font-weight: bold;
}

.links a:visited {
  color: #551A8B; /* Classic "visited link" purple */
}

.link-description {
  font-size: 24px;
  color: black;
  margin: 4px 0px 10px 0px;
  padding: 0;
}


/* Footer */
#footer {
  flex-shrink: 0;
  text-align: center;
  font-size: 12px;
  color: #000;
}

#footer hr {
  width: 90%;
  border: none;
  border-top: 1px solid #000;
}

/* Netscape button styling */
.netscape-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.netscape-button {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.netscape-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  background: url('/public/net.png') no-repeat center center;
  background-size: contain;
}

.netscape-text h1 {
  color: #322c2c;
  font-size: 24px;
  margin: 0;
  font-weight: bold;
}

.netscape-text p {
  color: #ff4500;
  font-size: 18px;
  margin: 0;
  font-style: italic;
  font-weight: bold;
}

.ribbon {
  margin: 0;
  padding: 0;
  background-color: #4CAF50;
  color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(-0%) translateY(-100%) rotate(-45deg);
  transform-origin: bottom right;
}


/* Investment page styles */
.investment-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.investment-categories {
  margin-top: 20px;
}

.category {
  margin-bottom: 20px;
}

.category h2 button {
  background: none;
  border: none;
  font-size: 24px;
  color: #0066cc;
  cursor: pointer;
  padding: 0;
}

.category ul {
  list-style-type: none;
  padding-left: 20px;
}

.category li button {
  background: none;
  border: none;
  font-size: 18px;
  color: #0066cc;
  cursor: pointer;
  padding: 0;
  margin-bottom: 10px;
}

.investment-details {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
}

.investment-details p {
  margin: 5px 0;
}

@media (max-width: 480px) {

  .investment-page {
    padding: 0px;
  }

  .investment-categories .category h2 button {
    font-size: 18px;
  }

  .investment-categories .category li button {
    font-size: 16px;
  }
}
